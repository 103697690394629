import './App.css';
import { useEffect, useRef } from 'react';
import { Typography, Hero, Slider } from './components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplittedTypography } from './components/ui/Typography';
import { useScreen } from './hooks';

gsap.registerPlugin(ScrollTrigger);

let lazyLoad = function () {
  var lazyImages = document.querySelectorAll('.lazy-img');
  var observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        entry.target.style.backgroundImage =
          'url(' + entry.target.dataset.src + ')';
        observer.unobserve(entry.target);
      }
    });
  });
  lazyImages.forEach(function (image) {
    observer.observe(image);
  });
};

const ResumeButton = (props) => (
  <a href="/AlaaEddinKseibatiResume-FrontendDeveloper.pdf" download>
    <button className="px-4 py-2  font-normal text-primary transition-[background-color] duration-150  hover:bg-primary  hover:bg-opacity-20 text-2xl border-2 border-primary">
      Resume
    </button>
  </a>
);
function MainNavbar() {
  const el = useRef();
  useEffect(() => {
    let ctx = gsap.context(() => {
      let tl = gsap.timeline();
      // tl.set(el.current, { borderColor: 'transparent' });
      // tl.from(el.current, {
      //   width: 0, // start with a width of 0
      //   duration: 4,
      //   ease: 'power2.out', // add easing to make the animation smoother
      // })
      // tl.to(
      //   el.current,
      //   {
      //     ease: 'power2.out', // add easing to make the animation smoother
      //     borderLeftColor: 'white',
      //     duration: 2,
      //   },
      //   '>'
      // );
      // tl.to(
      //   el.current,
      //   {
      //     ease: 'power2.out', // add easing to make the animation smoother
      //     borderTopColor: 'white',
      //     duration: 2,
      //   },
      //   '>'
      // );
      // tl.to(
      //   el.current,
      //   {
      //     ease: 'power2.out', // add easing to make the animation smoother
      //     borderRightColor: 'white',
      //     duration: 2,
      //   },
      //   '>'
      // );
      // tl.to(
      //   el.current,
      //   {
      //     ease: 'power2.out', // add easing to make the animation smoother
      //     borderBottomColor: 'white',
      //     duration: 2,
      //   },
      //   '>'
      // );
    });

    return () => {
      ctx?.revert?.();
    };
  }, [el]);
  return (
    <div
      ref={el}
      className="boundaries flex justify-between items-center min-h-[100px] "
    >
      {/* mx-4 ?? */}
      <div className="outline  p-2  outline-primary">
        <a
          href="https://www.linkedin.com/in/alaa-eddin-ksibati/"
          target="_blank"
        >
          <Typography size="lg" className=" text-primary font-bold">
            AK
          </Typography>
        </a>
      </div>
      <div>
        <ResumeButton />
      </div>
    </div>
  );
}

function HeaderContainer() {
  return (
    <div className="mb-12">
      <MainNavbar></MainNavbar>
      <Hero></Hero>
    </div>
  );
}

function Education() {
  const educationRef = useRef(null);
  return (
    <div className="boundaries flex flex-col gap-4">
      <SplittedTypography
        ref={educationRef}
        element="h2"
        size="xl"
        className=" text-primary"
        split
      >
        EDUCATION
      </SplittedTypography>
      <div className="flex flex-col gap-4 ">
        <Typography element="h6">
          Computer and Automation Engineering (
          <span className="text-secondary-lightest"> Bachelor's degree </span> )
        </Typography>
        <Typography element="p">
          Damascus University 11/2022 - Grade:
          <span className="text-secondary-lightest"> 78.19%</span> (Very Good)
        </Typography>
      </div>
    </div>
  );
}

function Experience() {
  const experienceRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const selectors = [
        ['#title2', '#content2'],
        ['#title3', '#content3'],
        ['#title4', '#content4'],
      ];
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: '#EXPERIENCE',
          start: 'top top',
          end: '+=390%',
          scrub: true,
          pin: '#EXPERIENCE',
          onEnterBack: () => tl.seek('reset'),
        },
      });

      [
        '#title1',
        '#title2',
        '#title3',
        '#title4',
        '#content1',
        '#content2',
        '#content3',
        '#content4',
      ].forEach((selector) => {
        tl.set(selector, { opacity: 0 });
      });
      tl.add('reset');
      let animate = (selector, delay = '+=1.5') => {
        tl.fromTo(
          selector[1],
          {
            opacity: 0,
          },
          {
            opacity: 1,
          },
          `>${delay}`
        );
        // show title 2
        tl.fromTo(
          selector[0],
          { y: 100, opacity: 0 },
          {
            y: 0,
            opacity: 1,
          },
          '<'
        );
        tl.add(`showEnd${selector[0]}`);
        // hide content 2
        tl.fromTo(
          selector[1],
          {
            opacity: 1,
          },
          {
            opacity: 0,
          },
          `showEnd${selector[0]}${delay}`
          // `>${delay}`
        );
        // hide title 2
        tl.fromTo(
          selector[0],
          { y: 0, opacity: 1 },
          {
            y: 100,
            opacity: 0,
          },
          '<'
        );
      };
      tl.fromTo('#LINE', { height: 0 }, { height: '100%', duration: 2 });
      animate(['#title1', '#content1']);
      animate(selectors[0]);
      animate(selectors[1]);
      animate(selectors[2]);
      tl.fromTo('#LINE', { height: '100%' }, { height: 0, duration: 2 });
    });
    return () => {
      ctx?.revert?.();
    };
  }, []);

  return (
    <div id="EXPERIENCE" className="boundaries h-[calc(100vh)] mb-12">
      <div className="boundaries">
        <SplittedTypography
          element="h2"
          size="xl"
          className=" text-primary"
          id="EXPERIENCE_TITLE"
          split
          delay={1}
          ref={experienceRef}
        >
          EXPERIENCE
        </SplittedTypography>
      </div>
      <div className="boundaries">
        <div className="boundaries  flex">
          <div
            className="boundaries h-[calc(100vh-56px)] relative flex-1  pt-4 md:pl-6"
            id="LEFT_SECTION"
          >
            <Typography
              size="sm"
              id="title1"
              className=" text-sm  absolute text-secondary-lightest"
            >
              Frontend TeamLead <br /> Disrupt-X <br /> 05/2022 - 01/2023
            </Typography>
            <Typography
              size="sm"
              id="title2"
              className=" absolute text-secondary-lightest"
            >
              Frontend Developer <br /> Disrupt-X <br /> 07/2021 - 05/2022
            </Typography>
            <Typography
              size="sm"
              id="title3"
              className=" absolute text-secondary-lightest"
            >
              Frontend Developer <br /> Global Share <br /> 05/2022 - 12/2022
            </Typography>
            <Typography
              size="sm"
              id="title4"
              className=" absolute text-secondary-lightest"
            >
              Full-Stack Developer <br /> SAWA ISP <br /> 12/2020 - 07/2021
            </Typography>
            <img
              className="absolute bottom-0 max-w-[100%] h-auto"
              src="exp-118x168.webp"
              srcSet="exp-118x168.webp 640w,
              exp-360x518.webp 1200w"
              alt="A Man photo"
              loading="lazy"
              sizes="(max-width: 640px) 100%, 100%"

            ></img>
            {/* sizes="(max-width: 640px) 100vw, 50vw" */}
            {/* <img
              src="/e3.png"
              className="absolute bottom-0 max-w-[100%] h-auto"
              loading="lazy"
              sizes=""
              alt="a man photo"
            ></img> */}
            <div
              id="LINE"
              className=" h-full bg-white w-[1px] right-0 top-0 absolute"
            ></div>
          </div>
          <div className="boundaries h-[calc(100vh-56px)] flex-[3] overflow-hidden relative">
            <div className="h-[calc(100vh-56px)]  absolute" id="content1">
              <Typography
                size="sm"
                className="pt-4 md:px-6 pl-1  sm:max-w-[90%]   text-secondary-light"
              >
                <div className="hidden md:block">
                  https://disrupt-x.io/ - https://cloud.disrupt-x.io/
                </div>
                <div>
                  <div>
                    After working as a Frontend Developer for approximately a
                    year, I was promoted to the role of Team Leader at
                    Disrupt-X, which was a great new experience.
                    <br /> During my time as Team Lead I did:
                  </div>
                  <ul>
                    <li>
                      successfully managed a team of four members by conducting
                      stand-ups and agile meetings to plan and deliver on
                      project goals.
                    </li>
                    <li>
                      led the process of building a new components design system
                      from scratch to enhance user experience with better UI.
                    </li>
                    <li>
                      facilitated code reviews and pair programming sessions to
                      ensure that we produced clean, high-quality code.
                    </li>
                    <li>
                      responsible for debugging errors and troubleshooting
                      issues that arose. To manage the team's tasks, I utilized
                      Jira
                      <span className="hidden md:inline-block">
                        ,which helped me to track and oversee the progress of
                        the team's work.
                      </span>
                    </li>
                  </ul>
                  <div>some of the new solutions a worked on:</div>
                  <div className="md:hidden block">
                    <span>Fleet Management,</span>
                    <span> Smart Parking, </span>
                    <span>Facility Booking,</span>
                    <span>Cloud Orbit,</span>
                    <span>Cognitive Neurons,</span>
                    <span>Cognitive Neurons.</span>
                  </div>
                  <ul className="hidden md:block">
                    <li>
                      <span>Fleet Management:</span>
                      An end-to-end solution for managing your fleet through
                      real-time tracking, route optimization, vehicle and driver
                      management, and continuous vehicle health monitoring.
                    </li>
                    <li>
                      <span> Smart Parking: </span>
                      An IoT solution for efficiently managing parking spaces,
                      tracking parking space utilization, and preventing
                      unauthorized access and overstays through optimized
                      sensors and an IoT platform.
                    </li>
                    <li>
                      <span>Facility Booking:</span>
                      Smart Meeting Room Booking Solution allow people to book
                      remotely and monitor the occupancy of any rooms such as
                      meeting rooms, recreational area and many more through a
                      platform using IoT.
                    </li>
                    <li>
                      <span>Cloud Orbit:</span>
                      IoT solution for monitoring waste fill levels and rodent
                      infestations.
                    </li>
                    <li>
                      <span>Cognitive Neurons:</span>
                      IoT solution for monitoring waste fill levels and rodent
                      infestations.
                    </li>
                    <li>
                      <span>Cognitive Neurons:</span>
                      AI Based Solution For Convenient Entry And Exit From
                      Parking Spaces Using Smart ANPR.
                    </li>
                    <li>
                      delivered a website for the biggest IoT Conference in the
                      MENA region, which was held in November 2022. I improved
                      the user experience by translating static content into
                      editable content using Google Sheet API. You can view the
                      website at this link: https://www.crackingiot.com/.
                    </li>
                  </ul>
                </div>
              </Typography>
            </div>
            <div className="h-[calc(100vh-56px)]  absolute" id="content2">
              <Typography
                size="sm"
                className="pt-4 md:px-6 pl-1  sm:max-w-[90%]   text-secondary-light"
              >
                <div>https://disrupt-x.io/ - https://cloud.disrupt-x.io/</div>
                <div>
                  Disrupt-x:Global IoT Solutions Platform is a UAE-based
                  platform provider that is committed to leveraging the growth
                  of technology and digitalization through the development of
                  smart AI-based solutions that deliver innovative IoT products.
                </div>
                <div>during my time at Disrupt-X i did:</div>
                <div>contribute in localization process of the platform.</div>
                <div>
                  Built Technical Documentation using static site generator SSG
                  `vuepress` explaining practices we followed in our Project
                  that prevented the need of onboarding course. -Developed
                  Reusable Components around the System.
                </div>
                <div>
                  -Worked on Real Time Ticketing system for customer support.
                </div>
                <div>
                  -Enhanced front-end performance through image optimization (
                  like using webp format , lazy loading ) and clean coding
                  practices, accelerating load times.
                </div>
                <div>
                  -worked on wrapper Vue js libraries for google map and
                  zingchart.
                </div>
              </Typography>
            </div>
            <div className="h-[calc(100vh-56px)]  absolute" id="content3">
              <Typography
                size="sm"
                className="pt-4 md:px-6 pl-1  sm:max-w-[90%]   text-secondary-light"
              >
                <div>Global Share - https://global-share.org/</div>
                <div>
                  Global Share social enterprise is building and empowering a
                  pool of talented people in different domains to develop
                  innovative educational applications and projects that support
                  developing countries’ students in accessing and generating
                  knowledge.
                </div>
                <div>
                  during my time at Global Share i did: Contributed in
                  developing a platform for academic and career development
                  collaboration called Helen. https://helen.global-share.org
                </div>
              </Typography>
            </div>
            <div className="h-[calc(100vh-56px)]  absolute " id="content4">
              <Typography
                size="sm"
                className="pt-4 md:px-6 pl-1  sm:max-w-[90%]   text-secondary-light"
              >
                <div>SAWA ISP - Full-stack Developer</div>
                <div>
                  SAWA – Internet Service Provider was established in 2005 as an
                  Internet provider. our services providing effective and
                  suitable internet solutions for both companies and
                  individuals. https://www.sawaisp.sy/
                </div>
                <div>
                  I have worked in programming department of the company as
                  full-stack developer. during my time at sawa ,i did:
                </div>
                <div>
                  -built the complete website for the international digital
                  conference 2021.
                </div>
                <div>
                  links: https://digitec-me.com/ |
                  https://www.facebook.com/digitec.conference
                </div>
                <div>
                  -Developed TMS ( Task Manganate System ): a system to manage
                  projects ( similar to Trello or Jira),and notify users via
                  email on tasks update.
                </div>
                <div>
                  -maintained and added new futures for existing projects which
                  were built using BFF architectural pattern.
                </div>
                <div>
                  -Built reusable code and libraries for future use, resulting
                  in 50% time savings.
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function Skills() {
  // TODO: make this effect as HOC
  const blackBox = useRef();
  useEffect(() => {
    let ctx = gsap.context(() => {
      const boxes = blackBox.current.querySelectorAll('.box');
      gsap.fromTo(
        boxes,
        {
          height: '100%',
          scaleY: 1,
          transformOrigin: 'bottom',
        },
        {
          // height: 0,
          scaleY: 0,
          delay: 1,
          stagger: 1,
          ease: 'power2.inOut', // add easing to the animation
          // transformOrigin:'bottom',
          transformOrigin: function (index) {
            // console.log(index);
            return index % 2 == 0 ? 'top' : 'bottom';
          },
          scrollTrigger: blackBox.current,
        }
      );
    });
    return () => {
      ctx?.revert?.();
    };
  }, [blackBox]);

  const skills1 = [
    'Javascript',
    'VueJs',
    'GIT',
    'Vuex',
    'Quasar Framework',
    'Vuetify',
    'Vuepress',
    'Nuxt',
    'Javascript',
  ];
  const skills2 = [
    'Tailwind Css',
    'CSS',
    'CSS3',
    'sass',
    'Html',
    'ReactJS',
    'Redux',
    'Mobx',
    'React Query',
    'Styled Component',
    'Material UI',
    'Tailwind Css',
  ];
  const skills3 = [
    'TypeScript',
    'E2E Testing - Cypress',
    'Unit Testing - Jest',
    'SQL',
    'Redis',
    'Jira',
    'MYSQL Databases',
    'Docker Basics',
    'Bootstrap',
    'Jquery',
    'webpack',
    'TypeScript',
  ];
  const skills4 = [
    'Vite',
    'Gsap',
    'Laravel',
    'RESTful APIs',
    'websocket',
    'OOP',
    'Design patterns',
    'Deep learning - knowledge',
    'Machine learning - knowledge',
    'Vite',
  ];
  return (
    <div className="boundaries relative mb-12">
      <div className="absolute flex w-full h-full black-box" ref={blackBox}>
        <div className="box flex-1 bg-primary bg-opacity-40 top-0  h-full z-10"></div>
        <div className="box flex-1 bg-primary bg-opacity-40 top-0  h-full z-10"></div>
        <div className="box flex-1 bg-primary bg-opacity-40 top-0  h-full z-10"></div>
        <div className="box flex-1 bg-primary bg-opacity-40 top-0  h-full z-10"></div>
        <div className="box flex-1 bg-primary bg-opacity-40 top-0  h-full z-10"></div>
        <div className="box flex-1 bg-primary bg-opacity-40 top-0  h-full z-10"></div>
        <div className="box flex-1 bg-primary bg-opacity-40 top-0  h-full z-10"></div>
        <div className="box flex-1 bg-primary bg-opacity-40 top-0  h-full z-10"></div>
      </div>
      <Typography element="h2" size="xl" className=" text-primary ">
        Skills
      </Typography>
      <div className="max-w-[80%] mx-auto mt-6 ">
        <Slider items={skills1}></Slider>
        <Slider items={skills2} reverse></Slider>
        <Slider items={skills3}></Slider>
        <Slider items={skills4} reverse></Slider>
      </div>
    </div>
  );
}
function Project({
  title = '',
  desc = '',
  leftImg,
  rightImg,
  tools = '',
  url,
}) {
  const screen = useScreen();
  const leftRef = useRef();
  const rightRef = useRef();
  const handleClick = () => {
    window.open(url, '_blank');
  };
  useEffect(() => {
    lazyLoad();
  }, []);
  useEffect(() => {
    gsap.set(leftRef.current, { transform: 'translate(50%,0) rotateZ(0deg' });
    gsap.set(rightRef.current, {
      transform: 'translate(-50%,0) rotateZ(0deg',
    });
    let ctx = gsap.context(() => {
      const tl = gsap.timeline();
      tl.to(leftRef.current, {
        transform: 'translate(-50%,0) rotateZ(-1.04328deg)',
        duration: 4,
        scrollTrigger: {
          trigger: leftRef.current,
          // // markers: true,s: true,
          start: '-=50% top',
          end: 'top 20%',
          scrub: 1,
          // ease: 'ease',
          // invalidateOnRefresh: true, // add this option fix resize calcs
        },
      });
      tl.to(rightRef.current, {
        transform: 'translate(50%,0) rotateZ(1.04328deg)',
        duration: 4,
        scrollTrigger: {
          trigger: rightRef.current,
          // markers: true,s: true,
          start: '-=50% top',
          end: 'top 20%',
          scrub: 1,
          // ease: 'ease',
          // invalidateOnRefresh: true, // add this option
        },
      });
    });
    return () => {
      // console.log('reverting');
      ctx?.revert?.();
    };
  }, [leftRef, rightRef, screen]);
  return (
    <div className="boundaries w-full relative">
      <div className="flex  h-[75vh] md:overflow-visible overflow-hidden w-full">
        <div
          ref={leftRef}
          className=" will-change-transform relative translate-x-[50%] w-[50%] h-full z-10  overflow-hidden rounded-md"
        >
          <div
            className="h-[85%] cursor-pointer lazy-img"
            onClick={() => handleClick()}
            data-src={leftImg}
            style={{
              // background: `url(${leftImg}) `,
              backgroundSize: 'cover',
              backgroundPosition: 'right',
            }}
          ></div>
          {/* <img src={`${leftImg}`} className="h-[85%] object-cover " /> */}
        </div>
        <div className="absolute left-[50%] translate-x-[-50%]   w-[40%] h-full">
          <Typography size="md" className="text-primary">
            {title}
          </Typography>
          <Typography
            size="sm"
            className="hidden md:block text-secondary-light"
          >
            {desc}
          </Typography>
          <Typography size="sm" className=" text-secondary-lightest ">
            {tools}
          </Typography>
        </div>
        <div
          ref={rightRef}
          className="will-change-transform relative translate-x-[-50%]  z-20  w-[50%] h-full overflow-hidden rounded-md"
        >
          <div
            className="h-[85%] cursor-pointer lazy-img"
            onClick={() => handleClick()}
            data-src={rightImg}
            style={{
              // background: `url(${leftImg}) `,
              backgroundSize: 'cover',
              backgroundPosition: 'left',
            }}
          ></div>
          {/* <img src={`${rightImg}`} className="h-[85%] object-cover " /> */}
        </div>
      </div>
    </div>
  );
}

function Projects() {
  const projects = [
    {
      leftImg: '/disrupt-x.jpg',
      rightImg: '/disrupt-x.jpg',
      title: 'Disrupt-X : Global IoT Solutions Platform',
      desc: 'Maintain Existing Solutions and\
      Adding new Solutions like:\
      Fleet Management,\
      Smart Parking,\
      Facility Booking,\
      Cloud Orbit,\
      Cognitive Neurons,\
      Cognitive Neurons.',
      tools: 'Used Tech: VueJS, Web-Sockets ,Google Map,ZingCharts.',
      url: 'https://cloud.disrupt-x.io/',
    },
    {
      leftImg: '/helen.webp',
      rightImg: '/helen.webp',
      title:
        'Helen Platform - A platform for collaborating on academic and career goals',
      desc: 'Contributed in Building the Frontend from the beginning stages of the project',
      tools: 'Used Tech: Vue 3, Composition Api.',
      url: 'https://helen.global-share.org/',
    },
    // {
    //   leftImg: '/food-delivery.jpg',
    //   rightImg: '/food-delivery.jpg',
    //   title: 'disrupt-x title',
    //   desc: 'disrupt-x desc',
    // },
    {
      leftImg: '/international-digital-transformation-conference.webp',
      rightImg: '/international-digital-transformation-conference.webp',
      title: 'International Digital Transformation Conference',
      desc: 'Delivered the complete web app including both the frontend and backend sides',
      tools: 'Used Tech: Laravel , Mysql Database, Jquery ,CSS.',
      url: 'https://digitec-me.com/',
    },
    {
      leftImg: '/portfolio.webp',
      rightImg: '/portfolio.webp',
      title: 'My Portfolio',
      desc: 'I have taken inspiration from the Awaaards website and turned my design ideas into a functional web app',
      tools: 'Used Tech: React Js , Tailwind CSS, GSAP.',
      url: 'https://alaa-eddin-kseibati-portfolio.pages.dev/',
    },
  ];
  return (
    <div className="boundaries">
      <Typography element="h2" size="xl" className=" text-primary ">
        PROJECTS I Worked On
      </Typography>
      {projects.map((p, index) => (
        <div key={`${index}-${p.title}`} className="my-12 py-12">
          <Project
            leftImg={p.leftImg}
            rightImg={p.rightImg}
            title={p.title}
            desc={p.desc}
            tools={p.tools}
            url={p.url}
          ></Project>
        </div>
      ))}
    </div>
  );
}

function PeopleIWasLuckyToWorkWith() {
  const screen = useScreen();
  const people = [
    {
      name: 'Ruba Ibrahim',
      position: 'Product Manager',
      profile: 'https://www.linkedin.com/in/ruba-ibrahim-4283071aa/',
    },
    {
      name: 'Abdulhadi Lababidi',
      position: 'Frontend Developer',
      profile: 'https://www.linkedin.com/in/abdulhadi-lababidi-a04974205/',
    },
    {
      name: 'Helal khadour',
      position: 'UI/UX Designer',
      profile: 'https://www.linkedin.com/in/helal-khadour/',
    },
    {
      name: 'Baraa Alsheekha',
      position: 'Frontend Developer',
      profile: 'https://www.linkedin.com/in/baraa-alsheekha-910067248/',
    },
    {
      name: 'Mahmod GH',
      position: 'Frontend Developer',
      profile: 'https://www.linkedin.com/in/mahmod-gh-74842b210/',
    },
    {
      name: 'Ibrahim Zanbily',
      position: 'Frontend Developer',
      profile: 'https://www.linkedin.com/in/ibrahim-z-407863158/',
    },
    {
      name: 'Mohammad Shehab',
      position: 'Backend Developer',
      profile: 'https://www.linkedin.com/in/mshv97/',
    },
    {
      name: 'Abd Alsattar Ardati',
      position: 'Managing Director',
      profile: 'https://www.linkedin.com/in/a-ardati/',
    },
    {
      name: 'Saleh Zamzam',
      position: 'Software Engineer',
      profile: 'https://www.linkedin.com/in/saleh-zamzam/',
    },
    {
      name: 'Sarah Rajab',
      position: 'Strategic Planning',
      profile: 'https://www.linkedin.com/in/sarah-rajab/',
    },
    {
      name: 'Sedra Abdlbaset',
      position: 'Product Manager',
      profile: 'https://www.linkedin.com/in/sedra-abdlbaset/',
    },
    {
      name: 'Ahmad Khalili',
      position: 'DevOps Engineer',
      profile: 'https://www.linkedin.com/in/ahmad-khalili-b40768190/',
    },
    {
      name: 'Dema Sharaf',
      position: 'Software Engineer',
      profile: 'https://www.linkedin.com/in/dema-sharaf',
    },
    {
      name: 'Cozette Cuzi',
      position: 'Full-stack Developer',
      profile: 'https://www.linkedin.com/in/cozette-cuzi/',
    },
    {
      name: 'Marah Daher',
      position: 'FrontEnd Developer',
      profile: 'https://www.linkedin.com/in/marah-daher193/',
    },
    {
      name: 'Hisham Al Haffar',
      position: 'Full-stack Developer',
      profile: 'https://www.linkedin.com/in/hisham-al-haffar-5b7b8958/',
    },
  ];
  const titleRef = useRef();
  const containerRef = useRef();
  const sectionRef = useRef();
  const textTopRef = useRef();
  const textBottomRef = useRef();
  const animateMob = () => {
    gsap.set(containerRef.current, { width: 0 });
    gsap.set(containerRef.current, { height: 'auto' });
    let ctxMb = gsap.context(() => {
      const tl = gsap.timeline({
        delay: 1,
        scrollTrigger: containerRef.current,
      });

      tl.to(containerRef.current, {
        width: '100%',
        duration: 2,
      });
      tl.fromTo(
        '.item',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          stagger: 0.5,
          // duration: 4,
        },
        '>'
      );
    });
    return ctxMb;
  };
  useEffect(() => {
    let ctx, ctxMb;
    if (screen > 900) {
      // console.log(screen, 'web');
      gsap.set(containerRef.current, { width: '100%' });
      gsap.set(containerRef.current, { height: 0 });
      ctx = gsap.context(() => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top top',
            end: '+=550%',
            scrub: true,
            pin: sectionRef.current,
          },
        });

        tl.to(containerRef.current, {
          height: '60vh',
          duration: 4,
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top top',
            end: '+=50%',
            scrub: true,
          },
        });
        tl.fromTo(
          '.item',
          {
            opacity: 0,
          },
          {
            opacity: 1,
            stagger: 2,
            duration: 20,
          },
          '>'
        );
        tl.fromTo(
          textTopRef.current,
          { color: 'rgb(136 146 176)' },
          {
            color: 'rgb(204 214 246)',
            duration: 4,
            scrollTrigger: {
              trigger: sectionRef.current,
              start: 'top top',
              end: '+=50%',
              scrub: true,
            },
          }
        );
        tl.fromTo(
          textBottomRef.current,
          { color: 'rgb(136 146 176)' },
          {
            color: 'rgb(100 255 218)',
            duration: 4,
            scrollTrigger: {
              trigger: sectionRef.current,
              start: 'top top',
              end: '+=50%',
              scrub: true,
            },
          }
        );
      });
    } else {
      // console.log(screen, 'mobile');
      ctxMb = animateMob();
    }
    return () => {
      // console.log('revert ctx', ctx);
      // console.log('revert ctxMb', ctxMb);
      ctx?.revert?.();
      ctxMb?.revert?.();
    };
  }, [sectionRef, textTopRef, textBottomRef, screen > 900]);
  return (
    <div className="boundaries mb-12 " ref={sectionRef}>
      <SplittedTypography
        split
        element="h2"
        size="xl"
        className=" text-primary "
        ref={titleRef}
      >
        People I Was Lucky To Work With
      </SplittedTypography>
      <div className="min-h-[calc(100vh-52px)] flex justify-center items-center">
        {/* height change */}
        <div
          className="relative w-0 h-full md:w-[80%] md:h-0  "
          ref={containerRef}
        >
          {/* img warapper */}
          <div className="max-h-[100%] h-[100%] md:px-12 py-4 md:py-0 max-w-[100%] bg-primary bg-opacity-40 md:overflow-hidden overflow-auto">
            {/* <img src={`/disrupt-x.jpg`} className=" object-cover " /> */}
            <div className="grid  h-[100%] items-center grid-cols-2 md:grid-cols-4 gap-4">
              {people.map((p) => (
                <a
                  href={p.profile}
                  key={p.name}
                  target="_blank"
                  className="block"
                >
                  <div className=" bg-navy text-secondary-lightest  p-2 md:h-auto h-[100%] item opacity-0">
                    <div>{p.name}</div>
                    <div>{p.position}</div>
                  </div>
                </a>
              ))}
            </div>
          </div>
          {/* top text */}
          <Typography
            size="xl"
            ref={textTopRef}
            className="hidden md:block  absolute sm:whitespace-nowrap text-lg top-0 z-10 -translate-y-1/2 left-1/2 -translate-x-1/2"
          >
            <span>Behind The</span>
            <span className=" opacity-0 pointer-events-none"> Success</span>
          </Typography>
          {/* bottom text */}
          <Typography
            size="xl"
            ref={textBottomRef}
            className="hidden md:block   absolute sm:whitespace-nowrap text-lg z-10 bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2"
          >
            <span className=" opacity-0 pointer-events-none">Behind The</span>
            <span> Success</span>
          </Typography>
        </div>
      </div>
    </div>
  );
}
function Footer() {
  return (
    <div className="boundaries flex justify-center w-full">
      <a href="mailto:alaa.eddin.ksibati.97@gmail.com">
        <Typography size="sm">alaa.eddin.ksibati.97@gmail.com</Typography>
      </a>
    </div>
  );
}

function App() {
  return (
    <div className="appLayout sm:px-12 bg-navy">
      <div className="App lg:mx-auto lg:max-w-[1251px]  ">
        <HeaderContainer></HeaderContainer>
        <Experience></Experience>
        <Skills></Skills>
        <Projects></Projects>
        <PeopleIWasLuckyToWorkWith></PeopleIWasLuckyToWorkWith>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;

import React, { forwardRef, useRef } from 'react';
import { withFadeIn } from '../../hoc/withFadeIn';
import { FadedTypography, SplittedTypography } from '../../ui/Typography';

const LetsTalkButton = forwardRef((props, ref) => (
  <a href="https://wa.me/+971558949210?text=Hi!" target="_blank" ref={ref}>
    <button className="px-4 py-2 font-medium text-primary transition-[background-color] duration-150  hover:bg-primary  hover:bg-opacity-20 text-3xl border-2 border-primary">
      Let's Talk
    </button>
  </a>
));
const FadedButton = withFadeIn(
  forwardRef((props, ref) => <LetsTalkButton ref={ref} />)
);
export const Intro = () => {
  const c1 = useRef(null);
  const c2 = useRef(null);
  const c3 = useRef(null);
  const c4 = useRef(null);
  const c5 = useRef(null);
  const c6 = useRef(null);
  return (
    <>
      <SplittedTypography
        element="h2"
        size="sm"
        className=" font-semibold text-primary"
        split
        ref={c1}
      >
        Hi, my name is
      </SplittedTypography>
      <SplittedTypography
        element="h2"
        size="xl"
        className=" font-semibold text-secondary-lightest"
        split
        delay={2}
        ref={c2}
      >
        Alaa Eddin Kseibati
      </SplittedTypography>
      <SplittedTypography
        element="h3"
        size="xl"
        className=" font-semibold text-secondary-light"
        split
        delay={4}
        ref={c3}
      >
        Frontend Developer
      </SplittedTypography>
      <FadedTypography
        element="h4"
        size="lg"
        className="text-secondary-light"
        delay={6}
        ref={c5}
      >
        SUMMARY
      </FadedTypography>
      <FadedTypography element="p" ref={c4} delay={6}>
        A Dubai-based Frontend Developer, Team Lead, and Engineer with
        <span className=" text-primary"> four years </span>
        of web development
        <span className=" text-primary"> experience</span>. Skilled in adapting
        to both remote and office work environments. Driving projects as a team
        lead, and building responsive websites for renowned conferences. Also
        worked on large projects and platforms for startups. Passionate about
        <span className=" text-primary"> learning </span> and
        <span className=" text-primary"> achieving </span>more.
      </FadedTypography>
      <div>
        <FadedButton delay={6} ref={c6}></FadedButton>
      </div>
    </>
  );
};
export * from './Hero';

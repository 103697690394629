import React from 'react';
import { Particles, withDelay } from '../../../components';
import { Intro } from '.';
import './Hero.css';
export function Hero() {
  const isMobile = window.innerWidth < 768;
  const DelayedParticles = withDelay(Particles, 4500);
  const DelayedIntro = withDelay(Intro, 5000);
  if (isMobile)
    return (
      <div className="boundaries min-h-[calc(100vh-100px)] flex flex-col md:flex-row items-center">
        <div className="flex-1 flex   lg:justify-center flex-col gap-4 2xl:min-h-[557px] min-h-[412px]">
          <Intro></Intro>
        </div>
        <div className="flex-1 h-[calc(100vh-100px)] min-h-[557px]  overflow-hidden">
          {/* <img src="/test.png"></img> */}
          <DelayedParticles></DelayedParticles>
        </div>
      </div>
    );
  else if (isMobile === false)
    return (
      <div className="boundaries min-h-[calc(100vh-100px)] flex flex-col md:flex-row items-center">
        <div className="flex-1 flex  flex-col gap-4 2xl:min-h-[557px] min-h-[412px]">
          <DelayedIntro></DelayedIntro>
        </div>
        <div className="flex-1 h-[calc(100vh-100px)] min-h-[557px]  overflow-hidden">
          {/* <img src="/test.png"></img> */}
          <Particles></Particles>
        </div>
      </div>
    );
  else return <></>;
}
